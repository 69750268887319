import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import AppShell from "components/AppShell";
import IeltsReport from "components/Report/IELTS";
import ToeflReport from "components/Report/TOEFL";
import { useLoading, useSnackbar } from "contexts";
import {
    getMockAttemptById, getMockAttemptByToken
} from "services/attemptService";
import { isBrowserSupported } from "utils";
import useStore from "utils/reportStore";
import Session from "utils/Session";

const MenuItems = [
    { title: "Home", link: "/home" },
    { title: "FAQs", link: "/home/faq" },
];

export default function Report() {
    const loading = useLoading();
    const snackbar = useSnackbar();
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const { type } = useParams();

    const setAttempt = useStore((state) => state.setAttempt);

    const isApiRequest = searchParams.get('api');

    React.useEffect(() => {
        (async () => {
            try {
                loading.show();

                const isDeviceSupported = await isBrowserSupported();
                if (isDeviceSupported) {

                    const accessToken = searchParams.get('at');
                    const attemptId = searchParams.get('aid');

                    let attempt;


                    if (accessToken) {
                        attempt = await getMockAttemptByToken(accessToken);
                    } else if (Session.isLoggedIn()) {
                        attempt = await getMockAttemptById(attemptId)
                    } else {
                        throw new Error("Invalid link");
                    }

                    if (!attempt) {
                        throw new Error('Attempt not found!');
                    }
                    
                    setAttempt(attempt);
                } else {
                    navigate('/unsupported');
                }

            } catch (error) {
                console.error(error);

                snackbar.error("Uh Oh! Something went wrong!", false);
                 if (!isApiRequest) navigate("/");
            } finally {
                loading.hide();
            }
        })();
    }, []);

    return (
        <AppShell
            menuItems={MenuItems} hideFooter hideAnnouncement
            hideHeader={isApiRequest}
        >
            {(type === 'toefl') ? <ToeflReport /> : null}
            {(type === 'ielts') ? <IeltsReport /> : null}
        </AppShell>
    );
}