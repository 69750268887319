import Session from "../utils/Session";
import Client from "./_client";

const apiUrl = process.env.REACT_APP_API_URL;

export const analyseAnswer = async (
  {
    section, attempt, questionType, timeTaken = 0, transcript = "",
    audioBlob = null, serialNumber, answer = [""], mockType, mockSection
  },
  onEvent = () => { }
) => {
  const formData = new FormData();

  formData.append("section", section);
  formData.append("questionType", questionType);
  formData.append("attempt", attempt);
  formData.append("timeTaken", timeTaken);
  formData.append("serialNumber", serialNumber);
  formData.append("audio", audioBlob);
  formData.append("transcript", transcript);
  formData.set("answer", answer);
  formData.set("mockType", mockType);
  formData.set("mockSection", mockSection);

  fetch(`${apiUrl}/analyse/mock`, {
    headers: {
      userId: Session.userId,
      "languify-application": "mock",
      "languify-access-token": Session.accessToken,
      "languify-session-id": localStorage.getItem("languifySessionId"),
    },
    method: "POST",
    body: formData,
  }).then(async result => {
    if (!(result.ok || result.status === 200)) {
      throw new Error(result.message || "Uh Oh! Unable to analyse the answer.");
    }

    result = await result.json();
    onEvent('analysed', result?.data);
  }).catch(err => {
    onEvent('error', err.message);
  });
};

export const analyseSpokenAnswer = (
  {
    attempt, section, serialNumber, timeTaken = 0,
    questionType, audioBlob = null, 
    transcript = [""], mockType, mockSection
  },
  onEvent = () => { }
) => {
  const httpRequest = new XMLHttpRequest();
  const formData = new FormData();

  formData.append("section", section);
  formData.append("questionType", questionType);
  formData.append("attempt", attempt);
  formData.append("timeTaken", timeTaken);
  formData.append("serialNumber", serialNumber);
  formData.append("answer", transcript);
  formData.append("mockType", mockType);
  formData.append("mockSection", mockSection);
  formData.append("audio", audioBlob);

  httpRequest.upload.addEventListener('progress', (e) => {
    const progress = Math.round((e.loaded / e.total) * 100);
    onEvent('progress', progress);
  });

  httpRequest.onload = (e) => {
    const response = JSON.parse(e?.target?.response || null);

    if (e?.target?.status !== 200) {
      onEvent('error', response?.data);
    } else if (response?.data?.isError) {
      onEvent('analysed', response?.data);
    } else {
      onEvent('analysed', response?.data);
    }
  };

  httpRequest.onabort = (e) => {
    onEvent('error', e);
  };

  httpRequest.onerror = (e) => {
    onEvent('error', e);
  };

  httpRequest.open('POST', `${apiUrl}/analyse/mock`, true);
  httpRequest.setRequestHeader('userId', Session.userId);
  httpRequest.setRequestHeader('languify-application', 'mock');
  httpRequest.setRequestHeader('languify-access-token', Session.accessToken);
  httpRequest.send(formData);
};


export const analyseSection = (
  { mockType, section, mockSection, attempt, answers = [] }, onEvent = () => { }
) => {
  new Client({
    path: `/analyse/mock/section`,
    payload: { mockType, section, mockSection, attempt, answers }
  }).post().then((result) => {

    if (!(result.ok || result.code === 200)) {
      onEvent('error', result.message);
      return;
    }

    onEvent('analysed', result?.data);
  }).catch((err) => {
    onEvent('error', err.message);
  });
};