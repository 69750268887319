import React from 'react';
import Button from 'components/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogTransition from 'components/DialogTransition';
import Divider from '@mui/material/Divider';
import { triggerEventForGA } from 'utils';

export default function EndTestDialog({ open, onSaveAndGetReport, onExitTest }) {

  const handleClick = () => {
    triggerEventForGA('save_progress_get_report_clicked');
    onSaveAndGetReport();
  }

  return (
    <Dialog
      keepMounted open={open} TransitionComponent={DialogTransition}
      onClose={onExitTest} maxWidth="sm"
    >
      <DialogTitle style={{ textTransform: "capitalize" }}>
        Resume test later and get feedback report for your current progress.
      </DialogTitle>
      <Divider light />
      <DialogContent sx={{ px: 6 }}>
        Note: Please note that the report will only include information on the
        completed sections.
      </DialogContent>
      <DialogActions sx={{ px: 6 }}>
        {/* <Button
          variant="contained" onClick={handleClick} sx={{ flexGrow: 1 }}
          color="error"
        >
          Save Progress & Get Report
        </Button> */}
        <Button variant="contained" onClick={onExitTest} sx={{ flexGrow: 1 }}>
          Exit Test
        </Button>
      </DialogActions>
    </Dialog>
  );
}