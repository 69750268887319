import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import ContentShell from 'components/ContentShell';
import ReadingSection from "components/IELTSMock/Reading";
import SpeakingSection from "components/IELTSMock/Speaking";
import WritingSection from "components/IELTSMock/Writing";
import ListeningSection from "components/IELTSMock/Listening";
import SavingAnswers from 'components/IELTSMock/SavingAnswers';
import SubmitSectionDialog from 'dialogs/IELTSSubmitSection';
import { analyseSpokenAnswer, finishMockAttempt } from 'services';
import useStore from 'utils/ieltsStore';
import Session from 'utils/Session';
import { exitFullScreen, fullScreen } from 'utils';

const getQuestionDefaultAnswer = (type) => {
  if (type === 'mcq') return [];
  if (type === 'scq') return -1;
  if (type === 'fib') return '';
  if (type === 'notes_fib') return '';
  if (type === 'written') return '';
  if (type === 'spoken') return '';
  return '';
};

export const endTest = (navigate) => {
  const referrer = window?.document?.referrer;
  if (referrer?.includes("languify") || referrer?.includes("localhost")) {
    window.close();
  } else {
    navigate("/home", { replace: true });
  }
}

function IELTSMock(props) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const analysisTasks = React.useRef([]);
  const [savingAnswers, setSavingAnswers] = React.useState(false);
  const [taskCount, setTaskCount] = React.useState(0);

  const mockType = useStore((state) => state.mockType);
  const attempt = useStore((state) => state.attempt);
  const section = useStore((state) => state.section);
  const sections = useStore(state => state.sections);
  const nextSection = useStore(state => state.nextSection);
  const nextQuestion = useStore(state => state.next);
  const reset = useStore((state) => state.reset);
  const setCurrentQuestion = useStore(state => state.setCurrentQuestion);
  const closeDialog = useStore(state => state.closeSubmitSectionDialog);

  const { questions } = React.useMemo(
    () => sections?.[section] || {}, [sections, section]
  );
  const type = useStore(state => state.sections[section]?.name?.toUpperCase());

  const totalQuestions = React.useMemo(
    () => questions?.length || 0, [questions]
  )

  const handleSubmitSection = React.useCallback(() => {
    setSavingAnswers(true);
    closeDialog();
  }, [closeDialog]);

  React.useEffect(() => {
    if (!mockType) {
      if (Session.isLoggedIn()) navigate("/home");
      else navigate("/choose-test?t=ielts");
    }
  }, [mockType, navigate]);

  React.useEffect(() => {
    questions?.forEach((q, serialNumber) => {
      q.answer = {
        questionType: q.type,
        serialNumber, section, attempt, mockType,
        value: getQuestionDefaultAnswer(q.type),
      };
    });
  }, [questions]);

  React.useEffect(() => {
    setCurrentQuestion(0);
  }, [section]);

  React.useLayoutEffect(() => fullScreen(), []);

  React.useEffect(() => {
    if (
      type === 'SPEAKING' &&
      taskCount === totalQuestions
    ) {
      handleSubmitSection();
    }
  }, [taskCount]);

  const onSaved = async () => {
    if (section + 1 < sections.length) {
      nextSection();
      setSavingAnswers(false);
    } else {
      try {
        await finishMockAttempt(attempt);
      } catch (error) { console.error(error) }

      exitFullScreen();

      const isApiRequest = searchParams.get('api');
      if (isApiRequest) {
        navigate("/end-assessment?&api=true", { replace: true });
      } else {

        if (Session.isLoggedIn()) {
          endTest(navigate, isApiRequest);
        } else {
          navigate(`/ielts/registration-info?aid=${attempt}`, { replace: true });
        }

      }

      reset();
    }
  };

  const analyseAnswer = (answer) => {
    nextQuestion();
    const task = {
      answer, completed: false, progress: 0, error: false,
      sNo: analysisTasks.current.length
    };

    const handleEvent = (task) => (event, data) => {
      if (event === 'progress') {
        task.progress = data;
      }
      if (event === 'analysed') {
        task.completed = true;
        task.progress = 100;
      }
      if (event === 'error') {
        task.error = true;
      }
    };

    task.eventHandler = handleEvent(task);
    task.service = analyseSpokenAnswer;

    analysisTasks.current.push(task);
    setTaskCount(tc => tc + 1);

    answer.mockType = mockType;
    answer.mockSection = sections?.[section]?._id;

    analyseSpokenAnswer(answer, handleEvent(task));
  };

  const sectionProps = {
    onTimeout: handleSubmitSection, analyseAnswer,
    canFinish: analysisTasks.current.length === totalQuestions
  };

  return (
    <ContentShell hideLogo light>
      {questions && (
        <>
          {(!savingAnswers && type === 'READING') && (<ReadingSection {...sectionProps} />)}
          {(!savingAnswers && type === 'LISTENING') && (<ListeningSection {...sectionProps} />)}
          {(!savingAnswers && type === 'SPEAKING') && (<SpeakingSection {...sectionProps} />)}
          {(!savingAnswers && type === 'WRITING') && (<WritingSection {...sectionProps} />)}
        </>
      )}
      {savingAnswers && (
        <SavingAnswers
          active questions={questions} onSaved={onSaved}
          analysisTasks={analysisTasks}
        />
      )}
      <SubmitSectionDialog
        section={type} onSubmit={handleSubmitSection}
      />
    </ContentShell >
  );
}

export default IELTSMock;