import React from "react";
import Box from '@mui/material/Box';
import Header from "./Header";
import Footer from "./Footer";
import Announcement from "components/Annoucement";
import Typography from "@mui/material/Typography";

function AppShell({
  placeAtTop, children, menuItems, hideHeader,
  hideFooter, hideAnnouncement, ...props
}) {
  return (
    <>
      <Box
        overflow='hidden'
        minHeight="100vh"
        display="flex"
        flexDirection="column"
        {...props}
      >
        {(!hideAnnouncement) && <Announcement />}
        {(!hideHeader) && <Header menuItems={menuItems} />}
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent={(placeAtTop) ? "flex-start" : "center"}
          flexGrow={1}
          minHeight={"calc(100vh - 80px)"}
        >
          {children}
        </Box>
        {(!hideFooter) && (
          <>
            <Footer />
            <Typography
              variant="caption"
              textAlign="center"
              sx={{
                backgroundColor: "primary.dark",
                color: "white.main",
                px: "10vw", py: 2
              }}
            >
              *All trademarks are the property of their respective owners.TOEFL®
              is a registered trademark of Educational Testing Service (ETS®).
              Languify is not endorsed or approved by ETS®.
              IELTS™ is a registered trademark of University of Cambridge ESOL,
              the British Council, and IDP Education Australia.
            </Typography>
          </>
        )}
      </Box>
    </>
  );
}

export default AppShell;
