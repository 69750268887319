import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import MockSelectDialog from "dialogs/MockSelectDialog";

const useStyles = makeStyles((theme) => ({
  button: {
    height: 24, objectFit: 'cover', margin: 4,
    [theme.breakpoints.down("md")]: { height: 18 }
  },
}));

export default function Announcement() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => setOpen(false);

  return (
    <Box sx={{
      width: "100%", display: "flex",
      alignItems: "center", justifyContent: "center", px: 1,
      background: "linear-gradient(90deg, rgba(45,171,255,0.5) 0%, rgba(246,251,255,1) 20%,rgba(255,255,255,1) 50%, rgba(246,251,255,1) 80%, rgba(45,171,255,0.5) 100%)",
    }}>
      <Typography variant="body1" m="0.5em 1em" fontSize="1vmax" fontWeight={500}>
        Free Communication skills certification and 6+ freebies,
        <span style={{ color: "#2DABFF", fontWeight: 700 }}>
          &nbsp; get the freebies now!
        </span>
      </Typography>
      <div
        // onClick={() => setOpen(o => !o)}
        style={{
          display: "flex", alignItems: 'center', justifyContent: 'center',
          cursor: "pointer",
        }}
      >
        <a href={process.env.REACT_APP_SEP_RESUME_URL} target='_blank' rel="noreferrer">
          <img
            src={"https://assets.languify.in/images/arrow-right-blue-icon.svg"}
            alt="arrow-right-blue-icon"
            className={classes.button}
          />
        </a>
      </div>
      <MockSelectDialog open={open} onClose={handleClose} />
    </Box>
  );
}