import React from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import TimeIcon from '@mui/icons-material/AccessAlarm';
import RightIcon from '@mui/icons-material/ArrowRightAlt';
import ContentShell from 'components/ContentShell';
import CenterFlexBox from 'components/CenterFlexBox';
import { useLoading, useSnackbar } from 'contexts';
import useIELTSStore from 'utils/ieltsStore';
import useTOEFLStore from 'utils/toeflStore';
import { isBrowserSupported, triggerEventForGA } from 'utils';
import { incrementUsageByCode } from 'services/referralService';
import Button from 'components/Button';
import { generateMock } from 'services/sectionService';
import MockSelectDialog from 'dialogs/MockSelectDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex", height: "fit-content",
    width: 'fit-content', background: theme.palette.primary.dark,
    flexDirection: "column", padding: 32, borderRadius: 16,
    [theme.breakpoints.down('md')]: {
      alignSelf: 'start', marginTop: 0, width: "100%",
      borderRadius: 0,
    },
  },
  typography: {
    color: "white", fontSize: 30, fontWeight: 600,
    [theme.breakpoints.down('md')]: { fontSize: 16, marginBottom: 0, }
  },
  subtitle: {
    color: "white", fontSize: 18,
    [theme.breakpoints.down('md')]: { fontSize: 12 }
  },
  sectionCardsContainer: {
    display: "flex", width: "100%", justifyContent: 'space-evenly',
    marginRight: 32, gap: 10,
    [theme.breakpoints.down('md')]: {
      display: 'grid', gridTemplateColumns: "auto auto",
      columnGap: 10, justifyContent: 'center'
    },
  },
  cardContainer: {
    display: "flex", width: "100%", justifyContent: "space-evenly",
    flexDirection: "column",
    margin: "32px 0px", minWidth: 'min(700px, 60vw)',
    [theme.breakpoints.down('md')]: {
      flexDirection: "column-reverse", alignItems: "center", minHeight: 400,
      margin: "16px 0px",
    },
  },
  sectionCard: {
    borderRadius: 8, padding: 16, position: "relative",
    background: theme.palette.subprimary.main, maxWidth: 180, minWidth: 180,
    cursor: "pointer",
    [theme.breakpoints.down('md')]: {
    },
    "&:hover": {
      boxShadow: "0px 0px 16px 0px #FFFFFF66"
    }
  },
  card: {
    borderRadius: 8, minWidth: 100, maxHeight: 'fit-content',
    padding: 16, position: "relative",
    background: theme.palette.subprimary.main,
    [theme.breakpoints.down('md')]: {
      maxWidth: 'fit-content', minWidth: 240,
    },
    "&:hover": {
      boxShadow: "0px 0px 16px 0px #FFFFFF66", transform: "scale(1.02)",
      cursor: "pointer",
    }
  },
  cardTitle: {
    margin: "8px 0px", fontSize: "max(80%,15px)", textAlign: "center", fontWeight: 600,
    [theme.breakpoints.down('md')]: {
      margin: 4, marginBottom: 0, maxWidth: "100%"
    }
  },
  iconContainer: {
    width: 45, height: 45, padding: 16,
    [theme.breakpoints.down('md')]: {
      minWidth: 40, width: 40, height: 40, padding: 10,
    },
    "&>img": {
      width: 18, height: 18,
      [theme.breakpoints.down('md')]: { width: 14, height: 14 }
    },
  },
  fullMockContainer: {
    display: 'flex', width: '100%', alignItems: 'center',
    justifyContent: 'center', gap: 20,
    [theme.breakpoints.down('md')]: { flexDirection: 'column' }
  },
  fullIconsContainer: {
    display: "flex", minWidth: 118, gap: 10,
    [theme.breakpoints.down('md')]: { minWidth: 88, gap: 4 }
  },
  proceedButton: {
    animation: "$pop 400ms linear infinite",
    animationFillMode: "both", color: "#fff",
    animationDirection: "alternate"
  },
  "@keyframes pop": {
    "0%": { transform: "scale(1)" },
    "100%": { transform: "scale(1.03)" },
  },
}));

const Sections = {
  ielts: [{
    title: "Speaking",
    icon: "mic-icon-pink.svg",
    duration: "15 Min",
    iconColor: "#F7E1FF"
  }, {
    title: "Writing",
    icon: "writing-icon-pink.svg",
    duration: "30 Min",
    iconColor: "#FFCCF7"
  }, {
    title: "Reading",
    icon: "reading-icon-yellow.svg",
    duration: "60 Min",
    iconColor: "#FFF4CC"
  }, {
    title: "Listening",
    icon: "headphones-icon-green.svg",
    duration: "30 Min",
    iconColor: "#D5FFD4"
  }],
  toefl: [{
    title: "Speaking",
    icon: "mic-icon-pink.svg",
    duration: "20 Min",
    iconColor: "#F7E1FF"
  }, {
    title: "Writing",
    icon: "writing-icon-pink.svg",
    duration: "60 Min",
    iconColor: "#FFCCF7"
  }, {
    title: "Reading",
    icon: "reading-icon-yellow.svg",
    duration: "45 Min",
    iconColor: "#FFF4CC"
  }, {
    title: "Listening",
    icon: "headphones-icon-green.svg",
    duration: "45 Min",
    iconColor: "#D5FFD4"
  }]
};

function IconContainer({ icon, title, iconColor }) {
  const classes = useStyles();

  return (
    <CenterFlexBox
      bgcolor={iconColor} borderRadius={'50%'} className={classes.iconContainer}
    >
      <img
        src={
          `https://assets.languify.in/images/${icon}`
        }
        alt={title}
        className={classes.icon}
      />
    </CenterFlexBox>
  )
}

function FullMockCard({ onClick, title, details, time, selected }) {
  const classes = useStyles();

  return (
    <CenterFlexBox
      column
      className={classes.card}
      onClick={onClick}
    >
      {selected && (
        <Box
          position="absolute" top={-2} right={-2} bgcolor="error.main" px={1}
          py={0.5} borderRadius="0px 0px 0px 8px"
        >
          <CheckIcon color="white" />
        </Box>
      )}
      <Box className={classes.fullIconsContainer}>
        {[
          { icon: "mic-icon-pink.svg", color: "#F7E1FF" },
          { icon: "writing-icon-pink.svg", color: "#FFCCF7" },
          { icon: "reading-icon-yellow.svg", color: "#FFF4CC" },
          { icon: "headphones-icon-green.svg", color: "#D5FFD4" }
        ].map(({ icon, color }, index) => (
          <IconContainer
            key={index} icon={icon} iconColor={color} title={index}
          />
        ))}
      </Box>
      <Typography
        variant="subtitle2" className={classes.cardTitle}
        color="white.main"
      >
        {title}
      </Typography>
      <Typography
        style={{
          display: "inline-flex", alignItems: "center",
          fontSize: 'max(1.8vmin,10px)', fontWeight: 500
        }}
        color="white.main"
      >
        {details}
      </Typography>
      <Typography
        style={{
          display: "inline-flex", alignItems: "center", fontSize: 'max(1.8vmin,10px)'
        }}
        mt={1}
        color="white.main"
      >
        <TimeIcon
          color="white"
          fontSize="small"
        />
        &nbsp;{time}
      </Typography>
    </CenterFlexBox>
  );
}

function SectionCard({
  selected, title, icon, duration, iconColor, onClick
}) {
  const classes = useStyles();

  return (
    <CenterFlexBox column className={classes.sectionCard} onClick={onClick}>
      {selected && (
        <Box
          position="absolute" top={-2} right={-2} bgcolor="error.main" px={1}
          py={0.5} borderRadius="0px 0px 0px 8px"
        >
          <CheckIcon color="white" />
        </Box>
      )}
      <IconContainer icon={icon} iconColor={iconColor} title={title} />
      <Typography
        variant="subtitle2" className={classes.cardTitle} color="white.main"
      >
        {title}
      </Typography>
      <Typography
        style={{
          display: "inline-flex", alignItems: "center",
          fontSize: 'max(1.5vmin,10px)', color: "white"
        }}
      >
        <TimeIcon color="white" fontSize="small" />
        &nbsp;{duration}
      </Typography>
    </CenterFlexBox>
  );
}

function TestTypeSelection() {
  const classes = useStyles();
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const loading = useLoading();
  const snackbar = useSnackbar();

  const setIELTSMock = useIELTSStore(state => state.setMock);
  const setIELTSReferralCode = useIELTSStore(state => state.setReferralCode);

  const setTOEFLMock = useTOEFLStore(state => state.setMock);
  const setTOEFLReferralCode = useTOEFLStore(state => state.setReferralCode);

  const [mockType, setMockType] = React.useState("ielts");
  const [sections, setSections] = React.useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [shortMock, setShortMock] = React.useState(false);
  const [fullMock, setFullMock] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      const referralCode = searchParams.get('rc');
      const mt = searchParams.get("t");

      if (referralCode) {
        setTOEFLReferralCode(referralCode);
        setIELTSReferralCode(referralCode);

        localStorage.setItem('rc', referralCode);

        try {
          loading.show();
          await incrementUsageByCode(referralCode);
        } catch (error) {
          console.error(error);
        } finally {
          loading.hide();
        }
      }

      if (mt && ["ielts", "toefl"].includes(mt)) {
        setMockType(mt);
      } else {
        setOpenDialog(true)
      }
    })();
  }, [searchParams]);

  React.useEffect(() => setSections([]), []);

  const handleClick = React.useCallback(async (section) => {
    try {
      loading.show();

      if (section === 'short') {
        setShortMock(!shortMock);
        setFullMock(false);
        setSections([]);
        return;
      }
      if (section === 'full') {
        setFullMock(!fullMock);
        setShortMock(false);
        setSections([]);
        return;
      }

      if (section !== undefined) {
        setShortMock(false);
        setFullMock(false);
        if (sections.includes(section)) {
          setSections(sections => sections.filter(s => s !== section));
        } else {
          setSections(sections => [...sections, section]);
        }
        return;
      }

      if (await isBrowserSupported()) {
        const sectionNames = (shortMock || fullMock) ?
          Sections[mockType].map((section) => section.title) :
          sections.map((section) => Sections[mockType][section].title);

        const mock = await generateMock(
          { type: mockType, sections: sectionNames, short: shortMock }
        );

        if (shortMock) triggerEventForGA(`${mockType}_short_test`);
        if (fullMock) triggerEventForGA(`${mockType}_full_test`);
        if (!(shortMock && fullMock)) {
          triggerEventForGA(`${mockType}_sectional_test`);
          if (sectionNames.length === 1)
            triggerEventForGA(`${mockType}_${sectionNames[0]?.toLowerCase()}_test`);
        }

        if (mockType === "toefl") setTOEFLMock(mock);
        if (mockType === "ielts") setIELTSMock(mock);

        navigate(`/${mockType}/assessment-intro`, { replace: true });
      } else {
        navigate(`/${mockType}/register`);
      }
    } catch (error) {
      snackbar.error(error.message);
      console.error(error);
    } finally {
      loading.hide();
    }
  }, [mockType, sections]);

  const handleClose = (type) => {
    if (type === "ielts") {
      navigate("/choose-test?t=ielts");
      setOpenDialog(false);
    }
    if (type === "toefl") {
      navigate("/choose-test?t=toefl");
      setOpenDialog(false);
    }
  }

  return (
    <ContentShell hideLogo>
      <Box className={classes.root}>
        <Typography className={classes.typography} >
          Choose Your Test Format :
        </Typography>
        <Typography
          fontFamily='inter' fontSize={15} color='white.main'
          fontWeight={600} mb={2}
        >
          Select between Full Length or Sectional Mocks
        </Typography>
        <Divider color="white.main" />


        <Typography
          fontFamily='inter' fontSize={15} color='white.main'
          fontWeight={500} mt={2} mb={1}
        >
          Select Sectional Mocks (Choose all sections you want to practice):
        </Typography>
        <Box className={classes.sectionCardsContainer}>
          {Sections[mockType].map(({ icon, title, duration, iconColor }, index) => (
            <SectionCard
              key={index}
              title={title}
              icon={icon}
              duration={duration}
              onClick={() => handleClick(index)}
              iconColor={iconColor}
              selected={sections.includes(index)}
            />
          ))}
        </Box>
        <br />
        <Typography
          fontFamily='inter' fontSize={15} color='white.main'
          fontWeight={500} mb={1}
        >
          Select Full Mock (All 4 sections included):
        </Typography>

        <Box
          className={classes.fullMockContainer}
        >
          <FullMockCard
            onClick={() => handleClick('short')}
            title='30 Minute Diagonistic'
            details='Take 30 min mock with all 4 sections' time='30 Min'
            selected={shortMock}
          />
          <Typography
            fontFamily='inter' fontSize={18} color='white.main' fontWeight={700}
          >
            Or
          </Typography>
          <FullMockCard
            onClick={() => handleClick('full')}
            title='3 Hour Comprehensive'
            details='Take 3 hour mock with all 4 sections' time='180 Min'
            selected={fullMock}
          />
        </Box>
        <br />
        <Button
          color="subprimary" variant="contained"
          className={classes.proceedButton}
          endIcon={<RightIcon />}
          onClick={() => handleClick()}
          style={{
            visibility: sections.length > 0 || (shortMock || fullMock) ? "visible" : "hidden",
          }}
        >
          Proceed
        </Button>
      </Box>
      <MockSelectDialog
        onClose={handleClose}
        open={openDialog}
      />
    </ContentShell >
  );
}

export default TestTypeSelection;

