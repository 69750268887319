import Client from "./_client";
import Session from "utils/Session";

export const finishMockAttempt = async (attempt, finish = true) => {

  const result = await new Client({
    path: `/mockAttempt/${attempt}/finish`,
    payload: finish ? { finishedAt: new Date() } : {}
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! unable to finish the attempt"
    );
  }

  return result?.data;
};

export const getMockAttempts = async (filters) => {
  let url = '/mockAttempt?';

  Object.keys(filters).forEach((key) => url += `${key}=${filters[key]}&`);

  const result = await new Client({ path: url, }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! unable to fetch the attempt"
    );
  }

  return result?.data;
};

export const createMockAttempt = async (
  { type, mockSections = [], referralCode = null }) => {

  const result = await new Client({
    path: `/mockAttempt`,
    payload: {
      type,
      mockSections,
      referralCode,
      user: Session.userId,
      userAgent: window.navigator.userAgent,
    },
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! unable start the attempt. Please try again!"
    );
  }

  return result?.data;
};

export const getMockAttemptById = async (id, user = true) => {

  let url = `/mockAttempt/${id}?mockSections=true`;

  if (user) url += '&user=true'

  const result = await new Client({
    path: url,
  }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Attempt fetch failed. Please try again!"
    );
  }

  return result?.data;
};

export const getMockAttemptByToken = async (token) => {
  const url = `/mockAttempt/token?at=${token}`;

  try {
    const result = await new Client({ path: url }).get();

    if (!(result.ok || result.code === 200)) {
      throw new Error(
        result.message || "Uh Oh! Attempt fetch failed. Please try again!"
      );
    }
    
    return result?.data;
  } catch (error) {
    throw new Error(
      error.message || "Uh Oh! Attempt fetch failed. Please try again!"
    );
  }
};
