import { useState, useEffect } from 'react';

const getReturnValues = (countDown) => {
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);
  return [days, hours, minutes, seconds, countDown];
};

const useCountDown = (seconds) => {
  const [countDown, setCountDown] = useState(seconds * 1000);

  useEffect(() => {
    const countDownDate = new Date().getTime() + (seconds * 1000);

    const updateCountDown = () => {
      const now = new Date().getTime();
      const distance = countDownDate - now;
      setCountDown(distance);

      if (distance <= 0) {
        clearInterval(interval);
      }
    };

    const interval = setInterval(updateCountDown, 1000);

    updateCountDown(); // Initial call to set the countdown immediately

    return () => clearInterval(interval);
  }, [seconds]);

  return getReturnValues(countDown);
};

export default useCountDown;
