import React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import CenterFlexBox from "components/CenterFlexBox";
import CountDown from "components/CountDown";
import beep, { playAudio } from 'utils/beep';
import { useConnected, useSnackbar } from 'contexts';

import Blinker from 'components/Blinker';
import useStore from 'utils/ieltsStore';
import Button from 'components/Button';
import aiBotImage from '../../../assets/image.png';


function Block({ disable = false, children, ...props }) {

  return (
    <CenterFlexBox
      minHeight={300} height={'60vh'} width='40%'
      borderRadius={3} flexDirection='column'
      border={(disable ? '1px solid #235598' : '2px solid #235598')}
      boxShadow={(disable ? 'none' : '8px 8px 24px rgba(0, 0, 0, 0.1)')}

      {...props}
    >
      {children}
    </CenterFlexBox>
  );
}

const SpeakingTime = 15;

function RecordQuestion({ active, question, analyseAnswer, speechRecorder }) {
  const snackbar = useSnackbar();
  const connected = useConnected();
  const questionTimeTakenRef = React.useRef(0);

  const [speakingTime, setSpeakingTime] = React.useState(0);

  const setRecording = useStore((state) => state.setRecording);

  const handleSpeechSuccess = React.useCallback((result) => {
    const answer = {
      ...question.answer,
      timeTaken: question.answer.timeTaken,
      ...result,
    };
  
    question.answer = answer;
  
    analyseAnswer(answer); 
  }, [question, analyseAnswer]);
  
  const submitAnswer = React.useCallback(() => {
    setRecording(false);
    setSpeakingTime(0);
    const timeTaken = Math.round(
      (Date.now() - questionTimeTakenRef.current) / 1000
    );

    question.answer.submitted = true;
    question.answer.timeTaken = timeTaken;
    question.answer.recording = false;

    speechRecorder.current?.stop(handleSpeechSuccess);
  }, [handleSpeechSuccess, question, speechRecorder]);

  const recordAnswer = React.useCallback(() => {
    if (active) {
      setRecording(true);

      questionTimeTakenRef.current = Date.now();

      speechRecorder.current?.start();

      setSpeakingTime(question.time || SpeakingTime);
    }
  }, [speechRecorder, question, active]);

  const handleQuestionAudioEnded = React.useCallback(() => {
    beep();
    recordAnswer();
  }, [recordAnswer]);

 /**
    * Effect to play audio automatically for each question once
    */
  React.useEffect(() => {
    if (connected) {
      const audio = playAudio(question.audio || null, (e) => {
        snackbar.error("Unable to play audio! Check your network connection");
      });

      audio.addEventListener('ended', handleQuestionAudioEnded);

      return () => {
        audio.currentTime = 0;
        audio.pause();
      };
    } else {
      snackbar.error("Unable to play audio! Check your network connection");
    }
  }, [question, connected]);

  return (
    <Box
      height={'-webkit-fill-available'} width={'100%'}
      display='flex' p={5} justifyContent='space-evenly'
      alignItems={'center'}
      position="relative"
    >
      <Block justifyContent='space-evenly' disable={!!speakingTime}>
        <Blinker on={!speakingTime}>
          <img
            src={(!!speakingTime) ?
              'https://assets.languify.in/gifs/ai-bot-stop.gif' :
              "https://assets.languify.in/gifs/ai-bot-speaking.gif"}
            alt="ai-bot" height={200} width={200}
            style={{
              borderRadius: "50%",
              boxShadow: !speakingTime ? "8px 8px 24px rgba(0, 0, 0, 0.4)" : "none",
              alignSelf: 'center'
            }}
          />
        </Blinker>
        <Typography fontFamily='inter' fontSize={18} fontWeight={500}>
          Speak after beep sound
        </Typography>
      </Block>

      <Block justifyContent='space-evenly' disable={!speakingTime}>
        <Box position="relative" width="100%" pt={5}>
          <Typography
            fontFamily='inter' fontSize={14}
            fontWeight={500} color='rgba(0, 0, 0, 0.6)'
            style={{
              visibility: !!speakingTime ? 'visible' : 'hidden',
              height: '1.5em', // Set a fixed height
              position: 'absolute',
              top: -30,
              left: 150
            }}
          >
            Time remaining for this question: &nbsp;
            {!!speakingTime && (
              <CountDown
                totalTime={speakingTime}
                onTimeout={submitAnswer}
                color="black"
                component="span"
              />
            )}
          </Typography>
          <CenterFlexBox flexDirection='column'>
            <Blinker on={!!speakingTime}>
              <img
                src={aiBotImage}
                alt="ai-bot" height={200} width={200}
                style={{
                  boxShadow: !speakingTime ? "8px 8px 24px rgba(0, 0, 0, 0.4)" : "none",
                  borderRadius: "50%",
                }}
              />
            </Blinker>
            <Typography
              fontFamily='Inter' fontSize={16} mt={3} ml={'2ch'}
              fontWeight={500} color='#235598'
              textAlign="center"
              style={{ visibility: !!speakingTime ? 'visible' : 'hidden' }}
            >
              Recording...
            </Typography>
            <Button
              onClick={submitAnswer}
              style={{ visibility: !!speakingTime ? 'visible' : 'hidden', marginTop: 16 }}
              variant="contained"
            >
              I'm done
            </Button>
          </CenterFlexBox>
        </Box>
      </Block>
    </Box>
  );
};

export default RecordQuestion;
