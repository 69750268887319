import React from "react";

import CheckIcon from '@mui/icons-material/Check';
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import Grow from "@mui/material/Grow";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: "flex", width: '100%', justifyContent: "space-evenly",
    alignItems: "center",
    padding: 32,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column', alignItems: "center"
    }
  },
  card: {
    display: "flex", flexDirection: 'column', alignItems: "center", justifyContent: 'center',
    width: 'min(200px,40vmin)', height: 'min(200px,30vmin)', borderRadius: 8,
    padding: "24px 16px 16px 16px", position: "relative",
    boxShadow: "0px 0px 16px 0px #00000066",
    animation: "$shake 1000ms ", color: "white",
    margin: 16,
    [theme.breakpoints.down('md')]: {
      padding: "16px 4px", height: 150, maxWidth: 200,
    },
    "&:hover": {
      transform: "scale(1.02)", cursor: "pointer",
      animation: "$shake 1000ms",
    }
  },
  "@keyframes shake": {
    "0%": { transform: "rotate(0deg)" },
    "5%": { transform: "rotate(1deg)" },
    "10%": { transform: "rotate(3deg)" },
    "15%": { transform: "rotate(1deg)" },
    "20%": { transform: "rotate(0eg)" },
    "25%": { transform: "rotate(-1deg)" },
    "30%": { transform: "rotate(-3deg)" },
    "35%": { transform: "rotate(-1deg)" },
    "50%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(0deg)" },
  },
  cardImage: {
    width: 'auto', height: 'max(5vw,68px)'
  },
  cardTitle: {
    margin: "8px 0px", fontSize: "max(80%,11px)", textAlign: "center",
    [theme.breakpoints.down('md')]: {}
  },
  cardDesc: {
    margin: "8px 0px", textAlign: "center", fontWeight: 600, width: "70%",
    [theme.breakpoints.down('md')]: {
      letterSpacing: "0px", margin: "0px", fontSize: "9px",
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

function Card({ url, image, title, desc, ...props }) {
  const classes = useStyles();

  const [selected, setSelected] = React.useState(false);

  React.useEffect(() => setSelected(false), []);

  const handleClick = () => {
    window.location.href = url;
  };

  return (
    <Box
      className={classes.card}
      onClick={handleClick}
      onMouseOver={() => setSelected(true)}
      onMouseLeave={() => setSelected(false)}
      {...props}
    >
      {selected && (
        <Box
          position="absolute" top={-1} right={-1} bgcolor="error.main" px={1}
          py={0.5} borderRadius="0px 0px 0px 8px"
        >
          <CheckIcon color="white" />
        </Box>
      )}
      <img
        src={`https://assets.languify.in/images/${image}`}
        alt={title}
        className={classes.cardImage}
      />
      <Typography variant="caption" className={classes.cardDesc}>
        {desc}
      </Typography>
    </Box>
  );
}

export default function MockSelectDialog({ open, onClose }) {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      onClose={onClose}
      fullWidth
      keepMounted
    >
      <DialogContent style={{ position: 'relative' }}>
        <Typography variant="h6" fontWeight="bold">
          Choose Test
        </Typography>
        <Typography variant="body2" mb={2}>
          Take Mock TOEFL or IELTS test
        </Typography>
        <Divider />
        <Box className={classes.cardContainer}>
          <Card
            image={"toefl-logo.png"}
            title="TOEFL"
            desc={<>Take free TOEFL Mock Test</>}
            url="/choose-test?t=toefl"
            style={{ animationDelay: "500ms", background: "#2DABFF" }}
          />
          OR
          <Card
            image={"ielts-logo.png"}
            title="IELTS"
            desc={<>Take free IELTS Mock Test</>}
            url="/choose-test?t=ielts"
            style={{ animationDelay: "1000ms", background: "#3fd39e" }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
