import React from "react";
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined';
import ResumeIcon from '@mui/icons-material/NotStartedOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import CenterFlexBox from "components/CenterFlexBox";
import { Table } from "components/Table";
import { useLoading, useSnackbar } from "contexts";
import { Link, useNavigate } from "react-router-dom";
import { getMockAttempts } from "services";
import { generateMock } from "services/sectionService";
import { formatDate } from "utils/date";
import useIETLSStore from "utils/ieltsStore";
import Session from "utils/Session";
import useTOEFLStore from "utils/toeflStore";
import { triggerEventForGA } from "utils";


const useStyles = makeStyles((theme) => ({
    analytics: {
        justifyContent: 'space-between', flexWrap: "wrap", gap: 8,
    },
    analytic: {
        flexDirection: 'column', backgroundColor: '#0F3854', height: 160,
        borderRadius: 4, padding: 16, width: '20%',
        justifyContent: 'space-around',
    },
    table: {
        bordeCollapse: 'collapse',
        borderRadius: '4px 4px 0px 0px',
        borderSpacing: 0,
        tableLayout: 'fixed',
        width: '100%',
        border: '1px solid white',
        backgroundColor: '#0F3854',
        borderBottom: 'none',
    },
    th: {
        width: '30%',
        maxWidth: '30%',
        minWidth: '30%',
        backgroundColor: '#49B6FF',
        border: 'none',
        color: '#fff',
        fontFamily: 'inherit',
        fontSize: '15px',
        fontWeight: 600,
        padding: '10px 5px',
        overflow: 'hidden',
        wordBreak: 'normal',
        verticalAlign: 'middle',
        '&:first-child': {
            borderRadius: '4px 0 0 0',

        },
        '&:last-child': {
            borderRadius: '0 4px 0 0',
        },
    },
    td: {
        width: '30%',
        color: 'white',
        fontFamily: 'inherit',
        fontSize: '14px',
        fontWeight: 600,
        padding: '12px 16px',
        textAlign: 'center',
        verticalAlign: 'top',
    },
}));

function ResumeMockButton({ attemptId, type, initTest, setMock, snackbar, navigate }) {
    const [loading, setLoading] = React.useState(false);

    const resumeMock = async () => {
        setLoading(true);
        let isMounted = true; // Add flag to track if component is mounted
        try {
            triggerEventForGA('test_resumed');
            
            const mock = await generateMock({ attemptId });

            if (isMounted) {
                setMock(mock);
                initTest({ attempt: attemptId });
                navigate(`/${type}/assessment`, { replace: true });
            }
        } catch (error) {
            console.error(error);
            if (isMounted) {
                snackbar.error(error.message);
            }
        } finally {
            if (isMounted) {
                setLoading(false);
            }
        }

        // Cleanup function to set flag to false on unmount
        return () => {
            isMounted = false;
        };
    };

    return loading ? (
        <CircularProgress color="success" size={24} />
    ) : (
        <Button
            variant="contained" startIcon={<ResumeIcon />}
            sx={{ textTransform: 'none', height: 35 }}
            onClick={resumeMock}
            disabled={loading}
        >
            Resume Test
        </Button>
    );
}


function ReportButton({ link }) {
    return (
        <Link to={link} style={{ textDecoration: "none" }}>
            <Button
                variant="contained" startIcon={<VisibilityOutlinedIcon />}
                sx={{ textTransform: 'none', height: 35 }}
            >
                View Report
            </Button>
        </Link>
    );
}

export default function Performance() {
    const classes = useStyles();
    const navigate = useNavigate();
    const snackbar = useSnackbar();
    const loading = useLoading();

    const initIELTSTest = useIETLSStore(state => state.initTest);
    const initTOEFLTest = useTOEFLStore(state => state.initTest);
    const setIELTSMock = useIETLSStore(state => state.setMock);
    const setTOEFLMock = useTOEFLStore(state => state.setMock);

    const [attempts, setAttempts] = React.useState([]);

    React.useEffect(() => {
        let isMounted = true; // Add flag to track if component is mounted

        (async () => {
            try {
                loading.show();
                const { mockAttempts } = await getMockAttempts({
                    user: Session.userId, order: "desc", orderBy: "createdAt"
                });

                if (isMounted) {
                    setAttempts(mockAttempts.map((mockAttempt) => {
                        const {
                            _id, type, createdAt, finishedAt, summary, sections
                        } = mockAttempt;

                        const isIELTS = type === 'ielts';
                        return {
                            "type": type?.toUpperCase() || '-',
                            score: finishedAt ? `${summary?.overall?.score}/${isIELTS ? '9' : (sections?.length * 30)}` : '-',
                            status: finishedAt ? "Finished" : "Pending",
                            "date": formatDate(createdAt),
                            action: finishedAt ? (
                                <ReportButton
                                    link={`/${type}/report?aid=${_id}`}
                                />
                            ) : (
                                <ResumeMockButton
                                    attemptId={_id}
                                    type={type}
                                    initTest={isIELTS ? initIELTSTest : initTOEFLTest}
                                    setMock={isIELTS ? setIELTSMock : setTOEFLMock}
                                    navigate={navigate}
                                    snackbar={snackbar}
                                />
                            )
                        };
                    }));
                }
            } catch (error) {
                console.error(error);
                if (isMounted) {
                    snackbar.error("Uh Oh! Unable to fetch attempts");
                }
            } finally {
                if (isMounted) {
                    loading.hide();
                }
            }
        })();

        // Cleanup function to set flag to false on unmount
        return () => {
            isMounted = false;
        };
    }, []);

    return (
        <>
            <Box display='flex' flexDirection='column' my={2} justifyContent='s'>
                <CenterFlexBox justifyContent='space-between'>
                    <CenterFlexBox
                        fontFamily='inter' fontSize={20}
                        fontWeight={500} color='white.main'
                        justifyContent='flex-start' mb={1}
                    >
                        <EqualizerOutlinedIcon /> &nbsp;
                        Track Your Performance &nbsp;
                    </CenterFlexBox>
                </CenterFlexBox>
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                    <Typography
                        fontFamily='inter' fontWeight={600}
                        color='white.main' fontSize={16}
                    >
                        Practice more and improve your scores!
                    </Typography>
                    <CenterFlexBox gap={2}>
                        <Button
                            variant='contained'
                            LinkComponent={'a'}
                            href="/choose-test?t=toefl"
                            target='_blank'
                            style={{ backgroundColor: '#49B6FF', fontWeight: 700, height: 35 }}
                            referrerPolicy={"origin"}
                        >
                            Free TOEFL Mock
                        </Button>
                        <Button
                            variant='contained'
                            LinkComponent={'a'}
                            href="/choose-test?t=ielts"
                            target='_blank'
                            style={{ backgroundColor: '#3FD39E', fontWeight: 700, height: 35 }}
                            referrerPolicy={"origin"}
                        >
                            Free IELTS Mock
                        </Button>
                    </CenterFlexBox>
                </Box>

                <br /><br />
                <Table
                    pagination
                    columns={['Test type', 'Score/Bands', 'Status', 'Date', 'Action']}
                    rows={attempts}
                    tableClass={classes.table}
                    thClass={classes.th}
                    tdClass={classes.td}
                />
                {(!attempts.length) && <CenterFlexBox
                    border={'1px solid #ffffff'} borderRadius='0px 0px 4px 4px'
                    borderTop='none' p={4}
                >
                    <Typography color='#ffffff' fontFamily='inter' fontWeight={600} fontSize={18}>
                        You have not taken any assessment yet!
                    </Typography>
                </CenterFlexBox>
                }
                <br /><br /><br />
            </Box>
        </>
    );
}
